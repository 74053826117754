import { createAsyncThunk } from "@reduxjs/toolkit";
import { toggleFollowUser } from "../../../api/wallet/toggleFollowUser";
import { FollowingStatus } from "../../token/api/types";

export interface FollowRequestData {
  followerId: number;
  followingId: number;
  status?: FollowingStatus;
}

export const toggleFollowUserAction = createAsyncThunk("tokens/toggleFollowUser", async (data: FollowRequestData) => {
  const response = await toggleFollowUser(data);
  return response.data;
});
