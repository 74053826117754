import { useEffect, useState } from "react";
import { useAppDispatch } from "../store/hooks";
import { changeStatusBottomMenu } from "../store/menu/bottomMenuReducer";

import solanaIcon from "../assets/icons/solana-with-background.svg";
import { moneyFormatWithTwoDigits } from "../helpers/moneyFormat";
import ContentRoleFilter from "../components/ContentChallenge/ContentRoleFilter";
import ChallengeForm from "../components/ContentChallenge/ChallengeForm";
import SubmissionsList from "../components/ContentChallenge/SubmissionsList";
import RulesButton from "../components/ContentChallenge/RulesButton";
import { CreatedBy, Video } from "../store/contentChallenge/api/types";
import { fetchVideosAction } from "../store/contentChallenge/actions/fetchVideosAction";
import { useSelector } from "react-redux";
import { contentChallengeVideosSelector } from "../store/contentChallenge/contentChallengeReducer";
import { virtualSolBalanceSelector } from "../store/account/account";

const ContentChallengePage = () => {
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState<CreatedBy>(CreatedBy.CREATOR);
  const [visibledVideos, setVisibledVideos] = useState<Video[]>([]);

  const allVideos = useSelector(contentChallengeVideosSelector);
  const virtualSolBalance = useSelector(virtualSolBalanceSelector);

  const handleSelectTab = (tab: CreatedBy) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    setVisibledVideos(
      allVideos
        .filter((video) => video.createdBy === activeTab)
        .sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()),
    );
  }, [allVideos, activeTab]);

  useEffect(() => {
    dispatch(changeStatusBottomMenu(false));
    dispatch(fetchVideosAction());
    window.scrollTo(0, 0);
    return () => {
      dispatch(changeStatusBottomMenu(true));
    };
  }, []);

  return (
    <div className="flex flex-col items-center pt-4 pb-[60px]">
      <div className="flex gap-[10px] items-center">
        <div className="flex justify-center relative w-[152px] h-[28px]">
          <div
            className=" w-[142px] h-full rounded-2xl flex justify-center items-center z-10 hover:scale-105 ease-in-out duration-300"
            style={{
              backgroundImage: "radial-gradient(151.56% 151.56% at 0% -20.31%, #E543FF 0%, #832DBF 100%)",
            }}
          >
            <img
              src={solanaIcon}
              alt="solana-icon"
              className="size-[13px] border-solid border-[1px] border-[#a899c1] rounded-full mr-[6px]"
            />
            <span className=" font-bold">{`SOL ${moneyFormatWithTwoDigits(virtualSolBalance, 5)} `}</span>
          </div>
          <div
            className="absolute top-1 w-[152px] h-[28px] rounded-2xl"
            style={{
              backgroundImage: "radial-gradient(151.56% 151.56% at 0% -20.31%, #E543FF 0%, #832DBF 100%)",
              filter: "blur(40px)",
            }}
          ></div>
        </div>
      </div>
      <h1
        style={{
          textShadow: `
            -1px -1px 0 black, 
            1px -1px 0 black, 
            -1px 1px 0 black, 
            1px 1px 0 black
            `,
        }}
        className="text-xl font-bold pt-[4vh]"
      >
        Content Creation Challenge
      </h1>
      <ContentRoleFilter activeTab={activeTab} onSelectTab={handleSelectTab} />
      {activeTab === CreatedBy.CREATOR ? (
        <p className="text-[#A0A0A0] text-base">You are a Creator. Upload your own video and get rewarded.</p>
      ) : (
        <p className="text-[#A0A0A0] text-base">
          You are Content Pirate. Take a video from this folder and upload to your account.
          <a className="underline text-white">Take video for upload here</a>
        </p>
      )}
      <ChallengeForm activeTab={activeTab} />
      <SubmissionsList videos={visibledVideos} />
      <RulesButton />
    </div>
  );
};

export default ContentChallengePage;
