import { Token } from "../../store/token/api/types.ts";
import { axiosInstance } from "../axios.ts";
import { apiRoutes } from "../routes.ts";
import { ApiResponse } from "../types.ts";

export async function getTokensByUser(userId: number) {
  return (
    await axiosInstance.get<ApiResponse<Token[]>>(apiRoutes.tokens.tokensByUser, {
      params: { userId },
    })
  ).data.data;
}
