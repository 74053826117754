import UserPhoto from "../../Common/UserPhoto";
import DarkBackground from "../../ui/DarkBackground";
import UserWalletInfo from "../UserWalletInfo";
import { GradientLine } from "../../ui/GradientLine";
import FollowButton from "./FollowButton";
import { useSelector } from "react-redux";
import { userIdSelector } from "../../../store/account/account";

interface UserSummaryProps {
  userName: string;
  userId: string | number;
  userPhoto?: string;
  followers: number;
  following: number;
  phantomWalletPublicKey: string;
  phantomWalletBalance?: string | number;
  isActiveWallet?: boolean;
  abilitytoRedirectToWalletManagement?: boolean;
}

const UserSummary = ({
  followers,
  following,
  userId,
  userPhoto,
  userName,
  phantomWalletPublicKey,
  isActiveWallet,
  abilitytoRedirectToWalletManagement = false,
}: UserSummaryProps) => {
  const myId = useSelector(userIdSelector);

  const isMyProfile = userId === myId;
  return (
    <DarkBackground>
      <div className="flex relative w-full flex-col gap-6 items-center justify-center py-4">
        <div className="flex flex-col justify-center items-center gap-[14px]">
          <UserPhoto styles="w-[88px] h-[88px] rounded-2xl pb-1" userPhoto={userPhoto} />
          <p className="text-[20px] font-semibold uppercase">{userName ? userName : `user${userId}`}</p>
          <div className="flex text-[#b5b5b5] relative justify-center items-center gap-6">
            <p>{followers} Followers</p>
            <GradientLine className=" h-[20px] w-[1px]" />
            <p>{following} Following</p>
          </div>
        </div>
        {!isMyProfile && (
          <div className="w-full">
            <FollowButton followerId={+myId!} followingId={+userId} />
          </div>
        )}
        <UserWalletInfo
          phantomWalletPublicKey={phantomWalletPublicKey}
          userId={userId}
          userName={userName}
          isActiveWallet={isActiveWallet}
          abilitytoRedirectToWalletManagement={abilitytoRedirectToWalletManagement}
        />
      </div>
    </DarkBackground>
  );
};

export default UserSummary;
