import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { isDemoSelector } from "../store/clicker/selectors";
import { OnboardingTour } from "../components/Clicker/OnboardingTour";
import CardsList from "../components/Cards/ImprovementCards/CardsList";
import DailyComboBlock from "../components/Cards/DailyCombo/DailyComboBlock";
import { ProfitPerHourBlock } from "../components/Clicker/ProfitPerHourBlock";
import StakingConditions from "../components/Common/StakingConditions";
import ExchangeBlock from "../components/ExchangeBlock";
import UserBalanceBlock from "../components/UserBalanceBlock";
import Title from "../components/ui/Title";
import { DataLayerEventNames, useDataLayer } from "../hooks/useDataLayer";
import { isOnboardingFinishedSelector } from "../store/onboarding/selectors";
import { completeDemoPortfolio } from "../api/clicker/completeDemoPortfolio";
import { useAppDispatch } from "../store/hooks";
import { finishOnboarding } from "../store/onboarding/onboardingReducer";

export function CardsPage() {
  const isDemo = useSelector(isDemoSelector);
  const isOnboardingFinished = useSelector(isOnboardingFinishedSelector);
  const [showOnboarding, setShowOnboarding] = useState(false);

  const dispatch = useAppDispatch();

  const { trackEvent } = useDataLayer();

  useEffect(() => {
    const checkCardExistence = setInterval(() => {
      const cards = document.getElementById("cards");

      if (cards) {
        clearInterval(checkCardExistence);
        setShowOnboarding(true);
      }
    }, 100);

    return () => {
      clearInterval(checkCardExistence);
    };
  }, []);

  return (
    <div>
      <div className="pb-[100px]">
        <div className="pb-2 flex gap-1">
          <UserBalanceBlock />
          <ExchangeBlock />
        </div>
        <div className="mt-[25px]">
          <Title text="Boost Your Passive Income" />
          <div className="flex gap-1 pt-1 w-full">
            <div className="flex-grow">
              <StakingConditions />
            </div>
            <div className=" flex-shrink flex-grow">
              <ProfitPerHourBlock statusVisible={false} />
            </div>
          </div>
        </div>
        <DailyComboBlock />

        <CardsList />
        {isDemo && showOnboarding && !isOnboardingFinished ? (
          <OnboardingTour
            steps={[
              {
                target: "#cards",
                content: "Invest in Cards that generate Hourly Passive Income",
                placement: "top",
              },
            ]}
            trackEvent={() => {
              trackEvent(DataLayerEventNames.cardsTipPassed);
              trackEvent(DataLayerEventNames.onboardingPassed);
            }}
            onEnd={async () => {
              await completeDemoPortfolio();
              dispatch(finishOnboarding());
            }}
          />
        ) : null}
      </div>
    </div>
  );
}
