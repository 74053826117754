export interface Video {
  id: string;
  createdAt: string;
  updatedAt: string;
  userId: number;
  status: SubmissionStatus;
  createdBy: "creator";
  challengeReward: string;
  video_url: string;
}

export enum SubmissionStatus {
  PENDING = "pending",
  REJECTED = "rejected",
  APPROVED = "approved",
}

export enum CreatedBy {
  CREATOR = "creator",
  CONTENT_PIRATE = "content-pirate",
}
