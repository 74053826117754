import { useEffect, useState } from "react";
import { useAppDispatch } from "../../store/hooks";
import { changeStatusBottomMenu } from "../../store/menu/bottomMenuReducer";
import UserSummary from "../../components/Pumpadur/Profile/UserSummary";
import CreatedTokensList from "../../components/Pumpadur/Profile/CreatedTokensList";
import TransactionList from "../../components/Pumpadur/Profile/Transaction/TransactionList";
import NftList from "../../components/Pumpadur/Profile/Nft/NftList";
import { useSelector } from "react-redux";
import { fetchCryptoProfileByUserAction } from "../../store/cryptoProfile/actions/fetchCryptoProfileByUserAction";
import { useParams } from "react-router-dom";
import { cryptoDataByUserSelector } from "../../store/cryptoProfile/cryptoProfileReducer";
import { getTokensByUser } from "../../api/tokens/getTokensByUser";
import { getTransactionsByUserId } from "../../api/transactions/getTransactionsByUserId";
import { Token, Transaction } from "../../store/token/api/types";
import ReferralSystem from "../../components/Common/ReferralSystem";
import { BackButton } from "@twa-dev/sdk/react";

const OwnerProfilePage = () => {
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [tokens, setTokens] = useState<Token[]>([]);

  const dispatch = useAppDispatch();
  const userCryptoData = useSelector(cryptoDataByUserSelector);

  const { userId } = useParams();
  const startPram = `pumpadur/owner-profile/${userId}`;

  const fetchTokensByUser = async () => {
    const result = await getTokensByUser(+userId!);
    setTokens(result);
  };

  const fetchTransactionsByUser = async () => {
    const result = await getTransactionsByUserId(+userId!);
    setTransactions(result);
  };

  useEffect(() => {
    dispatch(changeStatusBottomMenu(false));
    dispatch(fetchCryptoProfileByUserAction(+userId!));

    fetchTransactionsByUser();
    fetchTokensByUser();
    return () => {
      dispatch(changeStatusBottomMenu(true));
    };
  }, [dispatch, userId]);

  if (!userCryptoData) {
    return;
  }

  const {
    followers,
    following,
    user: { photo_url, username },
    publicKey,
  } = userCryptoData;

  return (
    <div className="relative pb-[20px]">
      <BackButton />
      <UserSummary
        followers={followers}
        following={following}
        userId={userId!}
        userName={username}
        userPhoto={photo_url}
        phantomWalletPublicKey={publicKey}
        isActiveWallet={true}
      />
      <div>
        <h2 className="text-center text-[12px] font-bold opacity-50 leading-none mt-5 mb-[14px]">Your Referral link</h2>
        <ReferralSystem text="Share" startParam={startPram} />
      </div>
      <NftList list={[]} isMyProfile={false} />
      <CreatedTokensList createdTokens={tokens} />
      <TransactionList transactions={transactions} />
    </div>
  );
};

export default OwnerProfilePage;
