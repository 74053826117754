import { useSelector } from "react-redux";
import TokenImage from "../TokenImage";
import CreatedBy from "./CreatedBy";
import TokenMetrics from "./TokenMetrics";
import { currentTokenSelector } from "../../../../store/token/tokensReducer";
import { NavLink } from "react-router-dom";
import { userIdSelector } from "../../../../store/account/account";

const TokenHeader = () => {
  const token = useSelector(currentTokenSelector);
  const myId = useSelector(userIdSelector);

  const {
    name,
    ticker,
    marcetCap,
    progress,
    logo,
    user: { id: followingId },
  } = token!;

  const isMyProfile = followingId === myId;
  return (
    <div className="pt-4">
      <div className="flex gap-4 leading-none">
        <NavLink to={`/pumpadur/tokens/token-info/${token?.id}`}>
          <TokenImage imageSrc={logo} />
        </NavLink>
        <div className="flex flex-col gap-[10px]">
          <NavLink to={`/pumpadur/tokens/token-info/${token?.id}`}>
            <p className="font-semibold text-[20px]  uppercase">{name}</p>{" "}
          </NavLink>
          <NavLink to={`/pumpadur/tokens/token-info/${token?.id}`}>
            <p className="font-semibold text-[16px] uppercase text-paleGrey">${ticker}</p>
          </NavLink>
          <NavLink to={isMyProfile ? "/pumpadur/profile" : `/pumpadur/owner-profile/${followingId}`}>
            <CreatedBy />
          </NavLink>
        </div>
      </div>
      <TokenMetrics progress={progress} marcetCap={marcetCap} />
    </div>
  );
};

export default TokenHeader;
