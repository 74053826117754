const RulesButton = () => {
  return (
    <div
      className="fixed bottom-0 left-0 right-0 pb-[2vh] pt-[6vh] text-center "
      style={{ background: "linear-gradient(180deg, rgba(81, 48, 101, 0) 0%, #4d2a61 100%)" }}
    >
      <a href="#" className="underline underline-offset-2  text-[16px] text-white/60">
        Rules
      </a>
    </div>
  );
};

export default RulesButton;
