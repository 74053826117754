import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCryptoProfileDataByUserId } from "../../../api/wallet/getCryptoProfileDataByUserId.ts";

export const fetchCryptoProfileByUserAction = createAsyncThunk(
  "account/fetchCryptoProfileByUser",
  async (userId: number) => {
    console.log("tut");

    return getCryptoProfileDataByUserId(userId);
  },
);
