import { createAsyncThunk } from "@reduxjs/toolkit";
import { postVideo } from "../../../api/contentChallenge/postVideo.ts";
import { CreatedBy } from "../api/types.ts";

interface SendVideoArgs {
  videoUrl: string;
  createdBy: CreatedBy;
}

export const sendVideoUrlAction = createAsyncThunk(
  "contentChallenge/sendVideoUrl",
  async ({ videoUrl, createdBy }: SendVideoArgs) => {
    return postVideo(videoUrl, createdBy);
  },
);
