import { PreloaderCircle } from "./PreloaderCircle.tsx";

interface ButtonProps {
  text: string;
  style?: string;
  className?: string;
  classNameIcon?: string;
  attributes?: Record<string, unknown>;
  handleClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  icon?: string;
  isPending?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  text,
  attributes,
  isPending,
  style = "linear-gradient(94.79deg, #FFAE34 -7.59%, #F37E3B 108.7%)",
  handleClick,
  className = "h-[56px]",
  classNameIcon = "w-[16px] h-[16px] mr-2",
  icon,
}) => {
  const updatedAttributes: Record<string, unknown> = {
    ...attributes,
    disabled: isPending ? true : attributes?.disabled,
  };

  const containsHTML = (text: string) => /<\/?[a-z][\s\S]*>/i.test(text);

  return (
    <button
      className={`w-full tracking-wide rounded-2xl   py-[23px] uppercase mb-[8px] font-bold hover:scale-105 ease-in duration-300 leading-none flex justify-center items-center ${className} ${isPending ? "pointer-events-none" : ""}`}
      style={{
        background: style,
      }}
      onClick={handleClick}
      {...updatedAttributes}
    >
      <span className="flex justify-center items-center relative">
        {isPending ? (
          <span className="text-[8px] absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2">
            <PreloaderCircle />
          </span>
        ) : (
          <>
            {icon && <img src={icon} className={`${classNameIcon}`} alt="friends" />}
            {containsHTML(text) ? <span dangerouslySetInnerHTML={{ __html: text }} className="flex" /> : text}
          </>
        )}
      </span>
    </button>
  );
};

export default Button;
