export enum TaskActionEnum {
  /**
   * @deprecated
   */
  OPEN_LINK = "OPEN_LINK",

  /**
   * @deprecated
   */
  JOIN_COMMUNITY = "JOIN_COMMUNITY",

  INVITE_FRIEND = "redirect to invite btn",
  REDIRECT_TO_GROUP = "redirect to group",
  REDIRECT_TO_CHAT = "redirect to chat",
  REDIRECT_TO_TWITTER = "redirect to twitter",
  REDIRECT_TO_EXCHANGE = "redirect to exchange",
  REDIRECT_TO_DISCORD = "redirect to discord",
  REDIRECT_TO_YOUTUBE = "redirect to youtube",
  REDIRECT_TO_OKX = "redirect to okx",
  REDIRECT_TO_OKX_DEPOSITE = "redirect to okx deposite",
}

export enum TaskStatus {
  /**
   * Before task started
   */
  TO_DO = "not started",

  /**
   * After task started
   */
  AWAITING = "started",

  /**
   * After task finished. Need to claim reward
   */
  CLAIM_REWARD = "completed",

  /**
   * After task finished and got reward
   */
  COMPLETE = "bonus issued",
}

export interface Task {
  id: number;
  status: TaskStatus;
  title: string;
  capacity: number;
  ticketCapacity: number;
  description: string | null;
  key: string;
  icon: string;
  action_name: TaskActionEnum;
  action_url: string;
  updatedAt: Date;
}
