import React, { useState } from "react";
import { GradientCard } from "../../ui/GradientCard";
import { toggleFollowUserAction } from "../../../store/cryptoProfile/actions/toggleFollowUserAction";
import { useAppDispatch } from "../../../store/hooks";

import FollowIcon from "../../../assets/icons/follow.svg?react";
import UnfollowIcon from "../../../assets/icons/unfollow.svg?react";

export enum FollowingStatus {
  FOLLOWING = "Following",
  UNFOLLOWING = "Unfollowing",
}

interface FollowButtonProps {
  followerId: number;
  followingId: number;
}

const FollowButton: React.FC<FollowButtonProps> = ({ followerId, followingId }) => {
  const [status, setStatus] = useState<FollowingStatus>(FollowingStatus.UNFOLLOWING);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const handleFollowToggle = async () => {
    setIsLoading(true);
    const newStatus = status === FollowingStatus.FOLLOWING ? FollowingStatus.UNFOLLOWING : FollowingStatus.FOLLOWING;

    try {
      await dispatch(toggleFollowUserAction({ followerId, followingId, status: newStatus }));

      setStatus(newStatus);
    } catch (error) {
      console.error("Failed to update follow status:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <GradientCard backgroundColor="#28202C">
      <div className="w-full flex flex-col justify-center">
        <button
          onClick={handleFollowToggle}
          disabled={isLoading}
          className="text-center py-3 flex flex-col items-center gap-1"
        >
          {status === FollowingStatus.FOLLOWING ? (
            <UnfollowIcon className={`size-6 fill-white`} />
          ) : (
            <FollowIcon className={`size-6 fill-white`} />
          )}
          {status === FollowingStatus.FOLLOWING ? "Unfollow" : "Follow"}
        </button>
      </div>
    </GradientCard>
  );
};

export default FollowButton;
