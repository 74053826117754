import { useSelector } from "react-redux";
import balanceIcon from "../../assets/icons/balance.svg";
import infoIcon from "../../assets/icons/info-without-border.svg";
import { moneyFormat } from "../../helpers/moneyFormat.ts";
import CircleStatusProfit from "./CircleStatusProfit.tsx";
import { NavLink } from "react-router-dom";
// import { finishStakingSelector, profitPerHourSelector } from "../../store/clicker/selectors.ts";
import { finishStakingSelector, isGameActiveSelector } from "../../store/clicker/selectors.ts";
import { profitPerHourSelector, profitPerHourToStakingSelector } from "../../store/account/account.tsx";
import { useEffect, useState } from "react";

enum PageEnum {
  CLICKER = "/",
  CARDS = "/cards",
}

export function ProfitPerHourBlock({ statusVisible = true }: { statusVisible?: boolean }) {
  const profitPerHour = useSelector(profitPerHourSelector);
  const profitPerHourToStaking = useSelector(profitPerHourToStakingSelector);
  const finishStaking = useSelector(finishStakingSelector);
  const isGameActive = useSelector(isGameActiveSelector);

  const [actualProfit, setActualProfit] = useState<number>(profitPerHour);
  const [nameBlock, setNameBlock] = useState<string>("Profit Per Hour");

  useEffect(() => {
    switch (window.location.pathname) {
      case PageEnum.CLICKER:
        setActualProfit(
          !isGameActive && profitPerHour > (profitPerHourToStaking || 0) ? profitPerHourToStaking : profitPerHour,
        );

        break;

      case PageEnum.CARDS:
        setActualProfit(profitPerHour);
        setNameBlock((prev) =>
          !isGameActive && profitPerHour > (profitPerHourToStaking || 0) ? "Next Profit Per Hour" : prev,
        );
        break;
    }
  }, [profitPerHour, profitPerHourToStaking]);

  const isActiveProfitPerHour = finishStaking ? finishStaking > new Date() : false;
  return (
    <NavLink
      to={`/profit-per-hour`}
      className="rounded-2xl bg-white/15 relative flex justify-center gap-3 w-full items-center p-1 overflow-hidden "
    >
      {statusVisible && <CircleStatusProfit isActive={isActiveProfitPerHour} />}
      <small
        className={`text-[12px] text-center flex flex-col ${!statusVisible && isGameActive ? "pl-[25px]" : ""}  ${!isActiveProfitPerHour || !statusVisible ? "" : "opacity-50"} text-yellow`}
      >
        <span>
          <span className="text-white font-medium">{nameBlock}</span>
        </span>

        <strong className="flex justify-center items-center gap-x-[0.286em] text-[14px]">
          +{moneyFormat(actualProfit || 0)}{" "}
          <span className="size-3">
            <img className="size-full object-cover" loading="lazy" src={balanceIcon} alt="currency" />
          </span>
        </strong>
      </small>
      <img className="size-[21px] object-cover" loading="lazy" src={infoIcon} alt="info" />
    </NavLink>
  );
}
