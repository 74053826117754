interface ScreenLayoutProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

export function ScreenLayout({ children, className = "", ...attrs }: ScreenLayoutProps) {
  return (
    <div {...attrs} className={`px-4 container bg-black pt-3 ${className}`}>
      {children}
    </div>
  );
}
