import { apiRoutes } from "../routes.ts";
import { axiosInstance } from "../axios.ts";
import { GetCryptoData } from "./types.ts";

export async function getCryptoProfileDataByUserId(userId: number) {
  return (
    await axiosInstance.get<GetCryptoData>(apiRoutes.wallet.getWalletsDataByUserId, {
      params: { userId },
    })
  ).data;
}
