import { GradientCard } from "../ui/GradientCard";

import contentChallengeTaskImage from "../../assets/img/content-challenge-task-image.svg";
import { ChipButton } from "../ui/ChipButton";
import { useNavigate } from "react-router-dom";

const ContentChallengeTask = () => {
  const navigate = useNavigate();

  return (
    <li key="content-challenge">
      <GradientCard backgroundColor="#28202C">
        <button
          type="button"
          className="flex-1 p-1 w-full flex gap-x-1 items-center pr-3"
          onClick={() => navigate("/content-challenge")}
        >
          <span className="flex grow gap-x-3 items-center">
            <span className="h-[56px] w-[56px] relative bg-white/25 rounded-xl overflow-hidden flex justify-center items-center">
              <img
                loading="lazy"
                className="size-full object-cover object-center"
                src={contentChallengeTaskImage}
                alt="Task icon"
              />
            </span>

            <span className="flex-1">
              <span className="text-left text-[16px] font-medium block break-all capitalize">
                Content creation challenge
              </span>
            </span>
          </span>

          <ChipButton as="span" text="Go" className="px-5" />
        </button>
      </GradientCard>
    </li>
  );
};

export default ContentChallengeTask;
