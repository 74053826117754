export type MenuItems = {
  title: string;
  icon: string;
  link?: string;
  emoji?: string;
  width: number;
};

export const menuItems: MenuItems[] = [
  // {
  //   title: "Rockets",
  //   icon: "rocket",
  //   link: "/",
  // },
  // {
  //   title: "Leaders",
  //   icon: "rocket",
  //   link: "/leaders",
  //   width: 69,
  // },
  {
    title: "Earn",
    icon: "earn",
    emoji: "⛏️",
    link: "/",
    width: 69,
  },
  {
    title: "Skills",
    icon: "skills",
    link: "/cards",
    width: 69,
  },
  {
    title: "Pumpadur",
    icon: "pumpadur",
    link: "/pumpadur/main",
    width: 69,
  },
  {
    title: "Friends",
    icon: "friends",
    link: "/friends",
    width: 69,
  },

  {
    title: "Tasks",
    icon: "tasks",
    link: "/tasks",
    emoji: "✅",
    width: 69,
  },
];
