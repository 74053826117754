import { WebAppUser } from "@twa-dev/types";
import { axiosInstance } from "../axios.ts";
import { apiRoutes } from "../routes.ts";

interface CheckInvitesInput {
  start_param: string;
  user?: WebAppUser;
}

export async function checkInvites({ start_param, user }: CheckInvitesInput) {
  console.log({start_param, user})
  return (
    await axiosInstance.post(apiRoutes.invites.checkInvites, {
      user: user || {},
      start_param,
    })
  ).data.data;
}
