import { useState, useEffect, useRef } from "react";

const TableHeader = () => {
  const [isFixed, setIsFixed] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);
  const headerRef = useRef<HTMLDivElement>(null);
  const placeholderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (headerRef.current && placeholderRef.current) {
        const placeholderRect = placeholderRef.current.getBoundingClientRect();

        if (placeholderRect.top <= 0) {
          setIsFixed(true);
        } else {
          setIsFixed(false);
        }
      }
    };

    const calculateHeight = () => {
      if (headerRef.current) {
        setHeaderHeight(headerRef.current.offsetHeight);
      }
    };

    calculateHeight();
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", calculateHeight);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", calculateHeight);
    };
  }, []);

  return (
    <>
      <div ref={placeholderRef} style={{ height: isFixed ? `${headerHeight}px` : "0px" }}></div>
      <div
        ref={headerRef}
        className={`-ml-4 -mr-4 px-4 text-[16px] z-50 ${isFixed ? "fixed top-0 left-0 right-0 px-8" : "relative"}`}
        style={{
          background: isFixed ? "#0D0810" : "transparent",
        }}
      >
        <div className="text-center text-white/60 font-bold pb-4 pt-[2vh]">Your submissions</div>
        <div className="flex text-center">
          <div className="pb-2 !font-normal flex-1 text-white/60">Video Id</div>
          <div className="pb-2 !font-normal flex-1 text-white/60">Date</div>
          <div className="pb-2 !font-normal flex-1 text-white/60">Status</div>
          <div className="pb-2 !font-normal flex-1 text-white/60">Reward</div>
        </div>
      </div>
    </>
  );
};

export default TableHeader;
