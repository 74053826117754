// import { useEffect, useRef, useState } from "react";
// import { moneyFormatWithTwoDigits } from "../../helpers/moneyFormat";
import SubmissionItem from "./SubmissionItem";
import { Video } from "../../store/contentChallenge/api/types";
import TableHeader from "./TableHeader";

interface SubmissionsListProps {
  videos: Video[];
}

const SubmissionsList = ({ videos }: SubmissionsListProps) => {
  return (
    <>
      {videos?.length > 0 && (
        <div>
          <TableHeader />

          <table className="w-full  table-fixed text-center text-[16px] leading-none max-h-[70vh]">
            <tbody className="mt-20">{videos?.map((item, index) => <SubmissionItem item={item} key={index} />)}</tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default SubmissionsList;
