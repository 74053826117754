import React from "react";
import Button from "../ui/Button";
interface Button {
  text: string;
  handler: () => void;
  bgColor?: string;
  position?: string;
  containerBackground?: string;
  attributes?: Record<string, unknown>;
  className?: string;
  isPending?: boolean;
}

const BottomButton: React.FC<Button> = ({
  text,
  handler,
  bgColor = "linear-gradient(94.79deg, #FFAE34 -7.59%, #F37E3B 108.7%)",
  position = "fixed  left-0 right-0 bottom-0",
  containerBackground = "linear-gradient(0deg, #482D59 0%, rgba(72, 45, 89, 0.8) 59.93%, rgba(72, 45, 89, 0) 100%)",
  attributes,
  className,
  isPending,
}) => {
  return (
    <div
      className={`px-4 pt-[40px] pb-4 ${position}  font-medium z-40`}
      style={{
        background: containerBackground,
      }}
    >
      <Button
        handleClick={handler}
        text={text}
        style={bgColor}
        className={className}
        attributes={attributes}
        isPending={isPending}
      />
    </div>
  );
};
export default BottomButton;
