import { createSlice } from "@reduxjs/toolkit";
import { Video } from "./api/types";
import { fetchVideosAction } from "./actions/fetchVideosAction";
import { RootState } from "../store";
import { sendVideoUrlAction } from "./actions/sendVideoUrlAction";

export interface contentChallengeState {
  pending: boolean;
  error: string | null;
  videos: Video[];
}

const initialState: contentChallengeState = {
  pending: false,
  error: null,
  videos: [],
};

const contentChallengeSlice = createSlice({
  name: "contentChallenge",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchVideosAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(fetchVideosAction.fulfilled, (state, action) => {
        state.pending = false;
        state.videos = action.payload;
      })

      .addCase(sendVideoUrlAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(sendVideoUrlAction.fulfilled, (state, action) => {
        state.pending = false;
        state.videos = action.payload;
      });
  },
});

export default contentChallengeSlice.reducer;

export const contentChallengeVideosSelector = (state: RootState) => state.contentChallenge.videos;
