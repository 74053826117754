import { configureStore } from "@reduxjs/toolkit";
import bottomMenuReducer from "./menu/bottomMenuReducer";
import rocketReducer from "./rocket/rocketReducer";
import roundReducer from "./round/roundReducer";
import clickerReducer from "./clicker/clickerReducer";
import accountReducer from "./account/account";
import friendsReducer from "./friends/friendsReducer.ts";
import tasksReducer from "./tasks/tasksReducer.ts";
import boostsReducer from "./boosts/boostsReducer.ts";
import toastNotificationsReducer from "./toastNotifications/toastNotificationsReducer.ts";
import onboardingReducer from "./onboarding/onboardingReducer.ts";
import globalReducer from "./global/globalReducer";
import * as Sentry from "@sentry/react";
import exchangesReducer from "./exchanges/exchangesReducer.tsx";
import leaguesReducer from "./leagues/leaguesReducer.ts";
import cardsReducer from "./cards/cardsReducer.ts";
import cryptoProfileReducer from "./cryptoProfile/cryptoProfileReducer.tsx";
import tokensReducer from "./token/tokensReducer.ts";
import nftsReducer from "./nft/nftsReducer.ts";
import contentChallengeReducer from "./contentChallenge/contentChallengeReducer.tsx";

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

export const store = configureStore({
  reducer: {
    toastNotifications: toastNotificationsReducer,
    bottomMenu: bottomMenuReducer,
    rocket: rocketReducer,
    round: roundReducer,
    clicker: clickerReducer,
    boosts: boostsReducer,
    account: accountReducer,
    friends: friendsReducer,
    tasks: tasksReducer,
    leagues: leaguesReducer,
    onboarding: onboardingReducer,
    global: globalReducer,
    exchanges: exchangesReducer,
    cards: cardsReducer,
    cryptoProfile: cryptoProfileReducer,
    tokens: tokensReducer,
    nft: nftsReducer,
    contentChallenge: contentChallengeReducer,
  },

  enhancers: (getDefaultEnhancers) => {
    return getDefaultEnhancers().concat(sentryReduxEnhancer);
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
