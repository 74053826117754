import { moneyFormatWithTwoDigits } from "../../helpers/moneyFormat";

import solanaIcon from "../../assets/icons/solana-with-background.svg";
import rejectIcon from "../../assets/icons/reject-icon.svg";
import approvedIcon from "../../assets/icons/done.svg";
import { SubmissionStatus, Video } from "../../store/contentChallenge/api/types";
import { useTelegramApp } from "../../hooks/useTelegramApp";

const SubmissionItem = ({ item }: { item: Video }) => {
  const { updatedAt, challengeReward, status, id, video_url } = item;

  const tgApp = useTelegramApp();

  let statusBlock;

  switch (status) {
    case SubmissionStatus.PENDING:
      statusBlock = "Pending";
      break;
    case SubmissionStatus.REJECTED:
      statusBlock = <img src={rejectIcon} alt="rejectIcon" className="inline  size-[12px] mr-[2px]" />;
      break;
    case SubmissionStatus.APPROVED:
      statusBlock = <img src={approvedIcon} alt="approvedIcon" className="inline  size-[12px] mr-[2px]" />;
      break;
  }

  const handleOpenVideo = () => {
    tgApp.openLink(video_url || "");
  };
  return (
    <tr>
      <td className="py-2 underline underline-offset-2" onClick={handleOpenVideo}>
        {id.slice(0, 8)}
      </td>
      <td className="py-2">{formatDate(updatedAt)}</td>
      <td className="py-2 ">
        <div>{statusBlock}</div>
      </td>
      <td className="py-2 text-yellow font-bold flex items-center">
        <img
          src={solanaIcon}
          alt="solana-icon"
          className="  size-[12px] mr-[2px] border-solid border-[1px] border-[#a899c1] rounded-full "
        />
        <span className="inline-block">{moneyFormatWithTwoDigits(+challengeReward, 5)}</span>
      </td>
    </tr>
  );
};

export default SubmissionItem;

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const day = String(date.getUTCDate()).padStart(2, "0");
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  return `${day}.${month}`;
};
