import React from "react";
import { CreatedBy } from "../../store/contentChallenge/api/types";

interface ContentRoleFilterProps {
  onSelectTab: (limit: CreatedBy) => void;
  activeTab: string;
}

const ContentRoleFilter: React.FC<ContentRoleFilterProps> = ({ onSelectTab, activeTab }) => {
  const handleTabClick = (tab: CreatedBy) => {
    onSelectTab(tab);
  };

  return (
    <div className="w-full flex justify-between font-semibold h-[40px] mt-[3.7vh] mb-[4.7vh]">
      {[CreatedBy.CREATOR, CreatedBy.CONTENT_PIRATE].map((status) => (
        <button
          key={status}
          className={`text-[16px] w-1/2 py-[10px]  border-b border-solid leading-none ${
            activeTab === status ? "text-white  border-white" : "text-white/50  border-none"
          }`}
          onClick={() => handleTabClick(status)}
        >
          {status.charAt(0).toUpperCase() + status.slice(1)}{" "}
        </button>
      ))}
    </div>
  );
};

export default ContentRoleFilter;
