import Title from "../../../ui/Title";
import TransactionItem from "./TransactionItem";
import { Transaction } from "../../../../store/token/api/types";

const TransactionList = ({ transactions }: { transactions: Transaction[] }) => {
  return (
    <>
      {transactions?.length > 0 && (
        <div className="pt-8">
          <Title text="Last Transactions" />
          <div className="flex flex-col gap-2">
            {transactions.map((transaction) => (
              <TransactionItem key={transaction.id} transaction={transaction} />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default TransactionList;
