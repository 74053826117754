import { useEffect, useState } from "react";
import { useAppDispatch } from "../../store/hooks";
import { changeStatusBottomMenu } from "../../store/menu/bottomMenuReducer";
import InputWidthLabel from "../../components/ui/InputWidthLabel";
import TextareaWidthLabel from "../../components/ui/TextareaWidthLabel";
import DarkBackground from "../../components/ui/DarkBackground";
import TokenImageNFTChoose from "../../components/Pumpadur/Token/TokenImageNFTChoose";
import BottomButton from "../../components/ui/BottomButton";
import { sendTokenAction } from "../../store/token/actions/sendTokenAction";
import { useSelector } from "react-redux";
import { isPendingSelector } from "../../store/token/tokensReducer";
import { useNavigate } from "react-router-dom";
// import { useVisualViewport } from "../../hooks/useVisualViewport";

const CreateTokenPage = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [name, setName] = useState<string>("");
  const [ticker, setTicker] = useState<string>("");
  const [storyOfMeme, setStoryOfMeme] = useState<string>("");
  const [telegramChannel, setTelegramChannel] = useState<string>("");
  const [telegramChat, setTelegramChat] = useState<string>("");
  const [twitter, setTwitter] = useState<string>("");
  const [tokenImage, setTokenImage] = useState<File | null>(null);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isBlurForm, setIsBlurForm] = useState<boolean>(true);
  const isPending = useSelector(isPendingSelector);
  useEffect(() => {
    dispatch(changeStatusBottomMenu(false));
    return () => {
      dispatch(changeStatusBottomMenu(true));
    };
  }, []);

  let isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

  // const { height } = useVisualViewport();

  useEffect(() => {
    validateForm();
  }, [name, ticker, storyOfMeme, telegramChannel, telegramChat, twitter, tokenImage]);

  const handleChange =
    (setter: React.Dispatch<React.SetStateAction<string>>) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setter(e.target.value);
    };

  const validateForm = () => {
    const newErrors: any = {};

    if (!name) newErrors.name = "Name is required";
    if (!ticker) newErrors.ticker = "Ticker is required";
    if (!storyOfMeme) newErrors.storyOfMeme = "Story of Meme is required";
    if (!tokenImage) newErrors.tokenImage = "Token image is required";

    const telegramUrlPattern = /^https:\/\/t\.me\//;

    if (!telegramChannel) {
      newErrors.telegramChannel = "Telegram channel is required";
    } else if (!telegramUrlPattern.test(telegramChannel)) {
      newErrors.telegramChannel = "Telegram channel must start with https://t.me/";
    }

    if (!telegramChat) {
      newErrors.telegramChat = "Telegram chat is required";
    } else if (!telegramUrlPattern.test(telegramChat)) {
      newErrors.telegramChat = "Telegram chat must start with https://t.me/";
    }

    if (!twitter) {
      newErrors.twitter = "Twitter handle is required";
    } else if (!/^https:\/\/x\.com\/.+/.test(twitter)) {
      newErrors.twitter = "Twitter handle must be a valid URL starting with https://twitter.com/";
    }

    setIsDisabled(Object.keys(newErrors).length > 0);
  };

  const handlePostToken = async () => {
    if (!isDisabled) {
      const formData = new FormData();

      if (tokenImage) {
        formData.append("file", tokenImage);
      }
      formData.append("name", name);
      formData.append("ticker", ticker);
      formData.append("description", storyOfMeme);
      formData.append("tgChatLink", telegramChat);
      formData.append("tgChannelLink", telegramChannel);
      formData.append("xTwitter", twitter);

      dispatch(sendTokenAction(formData))
        .unwrap()
        .then((response) => {
          const createdToken = response.data;
          navigate(`/pumpadur/tokens/${createdToken.id}`);
        });
    }
  };

  return (
    <div className="pb-[100px] pt-[3vh]">
      <DarkBackground>
        <TokenImageNFTChoose selectedTokenImage={tokenImage} setSelectedTokenImage={setTokenImage} />
      </DarkBackground>
      <form
        className="flex flex-col gap-2 pt-6"
        onBlur={() => setIsBlurForm(true)}
        onFocus={() => setIsBlurForm(false)}
      >
        <div className="rounded-2xl bg-mediumGrey/45">
          <div className="px-4">
            <InputWidthLabel label="Name" border={true} value={name} handleChange={handleChange(setName)} />
            <InputWidthLabel label="Ticker" value={ticker} handleChange={handleChange(setTicker)} />
          </div>
        </div>

        <TextareaWidthLabel
          label="Story of Meme"
          maxLength={1000}
          value={storyOfMeme}
          handleChange={(e) => setStoryOfMeme(e.target.value)}
        />

        <div className="rounded-2xl bg-mediumGrey/45 min-h-[160px]">
          <div className="px-4">
            <InputWidthLabel
              label="Telegram channel"
              border={true}
              value={telegramChannel}
              handleChange={handleChange(setTelegramChannel)}
            />
            <InputWidthLabel
              label="Telegram chat"
              border={true}
              value={telegramChat}
              handleChange={handleChange(setTelegramChat)}
            />
            <InputWidthLabel label="X (Twitter)" value={twitter} handleChange={handleChange(setTwitter)} />
          </div>
        </div>
      </form>
      <BottomButton
        text="Continue"
        handler={handlePostToken}
        attributes={{
          disabled: isDisabled,
        }}
        isPending={isPending}
        position={!isBlurForm && isIOS ? `relative -left-4 -right-4 w-[110%]` : "fixed  left-0 right-0 bottom-0"}
        containerBackground={
          !isBlurForm && isIOS
            ? ""
            : "linear-gradient(0deg, #482D59 0%, rgba(72, 45, 89, 0.8) 59.93%, rgba(72, 45, 89, 0) 100%)"
        }
        className={isDisabled ? "text-white/50" : "text-white"}
        bgColor={isDisabled ? "#563c66" : "linear-gradient(94.79deg, #FFAE34 -7.59%, #F37E3B 108.7%)"}
      />
    </div>
  );
};

export default CreateTokenPage;
