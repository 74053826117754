import { Transaction } from "../../store/token/api/types.ts";
import { axiosInstance } from "../axios.ts";
import { apiRoutes } from "../routes.ts";
import { ApiResponse } from "../types.ts";

export async function getTransactionsByUserId(userId: number) {
  return (
    await axiosInstance.get<ApiResponse<Transaction[]>>(apiRoutes.transactions.transactionByUserId, {
      params: { userId },
    })
  ).data.data;
}
