import { useSelector } from "react-redux";
import { photoSelector } from "../../store/account/account";
import userPhotoIcon from "../../assets/icons/user.svg";
import { ExternalImage } from "../ui/ExternalImage";

const UserPhoto = ({ styles, userPhoto }: { styles: string; userPhoto?: string }) => {
  const photo = userPhoto ? userPhoto : useSelector(photoSelector);
  const title = "Avatar";

  const photo_url = photo.endsWith(".svg") || photo.endsWith(".jpeg") ? photo : `${photo}.jpeg`;
  return (
    <div>
      {!photo ? (
        <img src={userPhotoIcon} className={`${styles} object-cover`} alt={title} />
      ) : (
        <ExternalImage src={photo_url} className={`${styles} object-cover`} alt={title} />
      )}
    </div>
  );
};

export default UserPhoto;
