import { useRef } from "react";

const useFocusHandler = <T extends HTMLTextAreaElement | HTMLInputElement>(paddingBottom: number) => {
  const inputRef = useRef<T>(null);

  const handleFocus = (e: React.FocusEvent<T>) => {
    e.preventDefault();

    const container = document.querySelector(".main-container") as HTMLElement;
    if (container) {
      container.style.paddingBottom = `${paddingBottom}px`;
    }

    if (inputRef.current) {
      const rect = inputRef.current.getBoundingClientRect();
      window.scrollTo({
        top: rect.top + window.pageYOffset - paddingBottom,
        behavior: "smooth",
      });
    }
  };

  const handleBlur = () => {
    const container = document.querySelector(".main-container") as HTMLElement;
    if (container) {
      container.style.paddingBottom = "0px";
      window.scrollTo(0, 0);
    }
  };

  return { inputRef, handleFocus, handleBlur };
};

export default useFocusHandler;
